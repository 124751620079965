import React from "react";
import { Upload, message, Form, Input, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";

const FileUpload = () => {
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    const { file, description, rating } = values;
    const formData = new FormData();
    formData.append("file", file.originFileObj);
    formData.append("description", description);
    formData.append("rating", rating);

    try {
      const response = await axios.post(
        "https://aestraswift.ocpl.tech/api2/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      message.success("Upload successful");
      console.log(response.data);

      // Reset the form fields after successful upload
      form.resetFields();
    } catch (error) {
      console.error("Error uploading file:", error);
      message.error("Upload failed");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        minHeight: "50vh",
        alignItems: "center",
      }}
    >
      <div>
        <h1>Upload Profile</h1>
        <Form
          form={form}
          name="upload_profile"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{ rating: "" }}
        >
          <Form.Item
            name="file"
            label="File"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[
              { required: true, message: "Please select a file to upload." },
            ]}
          >
            <Upload name="logo" maxCount={1}>
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>

          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: "Please enter a description." }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="rating"
            label="Rating"
            rules={[{ required: true, message: "Please enter a rating." }]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Upload
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default FileUpload;
