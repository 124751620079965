import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Layout, Input, Button, message } from "antd";
import Navbar from "./Navbar";
import FormDataDisplay from "./FormDataDisplay";
import FileUpload from "./FileUpload";

const { Content } = Layout;

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [password, setPassword] = useState("");
  const correctPassword = "Admin@123"; // Replace 'yourpassword' with the actual password

  const handleLogin = () => {
    if (password === correctPassword) {
      setLoggedIn(true);
    } else {
      message.error("Incorrect password. Please try again.");
    }
  };

  return (
    <Router>
      <Layout style={{ minHeight: "100vh" }}>
        {!loggedIn && (
          <Login setPassword={setPassword} handleLogin={handleLogin} />
        )}
        {loggedIn && (
          <>
            <Navbar />
            <Content style={{ padding: "0 50px" }}>
              <div style={{ background: "#fff", padding: 24, minHeight: 280 }}>
                <Routes>
                  <Route path="/upload" element={<FileUpload />} />
                  <Route path="/view-data" element={<FormDataDisplay />} />
                  <Route path="/" element={<Home />} />
                </Routes>
              </div>
            </Content>
          </>
        )}
      </Layout>
    </Router>
  );
}

function Home() {
  return <h1>Welcome to the Khalsa Portal</h1>;
}

function Login({ setPassword, handleLogin }) {
  return (
    <div style={{ textAlign: "center", marginTop: "20%" }}>
      <h2>Please login to access the portal:</h2>
      <Input.Password
        style={{ width: 200, marginBottom: 10 }}
        placeholder="Enter password"
        onChange={(e) => setPassword(e.target.value)}
      />
      <Button type="primary" onClick={handleLogin}>
        Login
      </Button>
    </div>
  );
}

export default App;
