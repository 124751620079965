import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table, Typography } from "antd";

const { Title } = Typography;

const FormDataDisplay = () => {
  const [formData, setFormData] = useState([]);

  useEffect(() => {
    fetchFormData();
  }, []);

  const fetchFormData = async () => {
    try {
      const response = await axios.get("https://aestraswift.ocpl.tech/api2/form/all");
      setFormData(response.data.reverse());
    } catch (error) {
      console.error("Error fetching form data:", error);
    }
  };

  // Define columns for Table
  const columns = [
    {
      title: "Form Type",
      dataIndex: "formType",
      key: "formType",
      sorter: (a, b) => a.formType.localeCompare(b.formType),
    },
    {
      title: "To City",
      dataIndex: "toCity",
      key: "toCity",
      sorter: (a, b) => a.toCity.localeCompare(b.toCity),
    },
    {
      title: "From City",
      dataIndex: "fromCity",
      key: "fromCity",
      sorter: (a, b) => a.fromCity.localeCompare(b.fromCity),
    },
    {
      title: "Configuration",
      dataIndex: "configuration",
      key: "configuration",
      sorter: (a, b) => a.configuration.localeCompare(b.configuration),
    },
    {
      title: "Name",
      dataIndex: "yourName",
      key: "yourName",
      sorter: (a, b) => a.yourName.localeCompare(b.yourName),
    },
    {
      title: "Mobile No",
      dataIndex: "mobileNo",
      key: "mobileNo",
      sorter: (a, b) => a.mobileNo.localeCompare(b.mobileNo),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: "Enquiry",
      dataIndex: "enquiry",
      key: "enquiry",
      sorter: (a, b) => a.enquiry.localeCompare(b.enquiry),
    },
    {
      title: "DOB",
      dataIndex: "dob",
      key: "dob",
      sorter: (a, b) => new Date(a.dob) - new Date(b.dob),
      render: (dob) => new Date(dob).toLocaleDateString(),
    },
  ];

  return (
    <div style={{ padding: "20px" }}>
      <Title level={2}>Form Data</Title>
      <Table
        columns={columns}
        dataSource={formData}
        rowKey={(record) => record._id}
        pagination={{ pageSize: 10 }}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
};

export default FormDataDisplay;
